import { merge } from 'lodash-es';
import { textStyles as foundation } from './foundations/textStyles';
const customTextStyles = {
    'h3-semibold': {
        fontWeight: 600,
        lineHeight: '2.25rem',
        fontSize: '1.75rem',
        letterSpacing: '-0.019em',
        fontFamily: 'body',
    },
    'subhead-2': {
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        letterSpacing: '0',
        fontFamily: 'body',
    },
    link: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: '1.25rem',
        letterSpacing: '-0.00525rem',
        textDecorationLine: 'underline',
    },
};
export const textStyles = merge(customTextStyles, foundation);
